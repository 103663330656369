import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props, Link } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
import { Grid } from "./Grid";
import { PropsTable } from "./Item.props";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "griditem"
    }}>{`Grid.Item`}</h1>
    <p>{`Grid item component, it must be used as direct children of `}<inlineCode parentName="p">{`Grid`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <Code className="bash" mdxType="Code">{`yarn add @madebywild/styled-grid`}</Code>
    <Code className="js" mdxType="Code">{`import { Grid } from '@madebywild/styled-grid'`}</Code>
    <h2 {...{
      "id": "props-table"
    }}>{`Props table`}</h2>
    <Props of={PropsTable} mdxType="Props" />
    <h3 {...{
      "id": "row-column"
    }}>{`Row Column`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Grid.Item row={[1, 3]} /> // grid-row: 1 / 3
<Grid.Item column={[1, 4]} /> // grid-column: 1 / 4
<Grid.Item column={2} /> // grid-column: 2
`}</code></pre>
    <h3 {...{
      "id": "alignment"
    }}>{`Alignment`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Grid.Item alignSelf={"start"} /> // align-self: flex-start
<Grid.Item alignSelf={"end"} /> // align-self: flex-end
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={3} __code={'<Grid rows={3} columns={2} gap={10}>\n  <Grid.Item row={[1, 3]} style={{ padding: 20, background: \'darkgray\' }} />\n  <Grid.Item style={{ padding: 20, background: \'darkgray\' }} />\n  <Grid.Item style={{ padding: 20, background: \'darkgray\' }} />\n  <Grid.Item\n    column={[1, 3]}\n    style={{ padding: 20, background: \'darkgray\' }}\n  />\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Link,
      Code,
      Grid,
      PropsTable,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid rows={3} columns={2} gap={10} mdxType="Grid">
    <Grid.Item row={[1, 3]} style={{
          padding: 20,
          background: "darkgray"
        }} />
    <Grid.Item style={{
          padding: 20,
          background: "darkgray"
        }} />
    <Grid.Item style={{
          padding: 20,
          background: "darkgray"
        }} />
    <Grid.Item column={[1, 3]} style={{
          padding: 20,
          background: "darkgray"
        }} />
  </Grid>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      